import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'; // 引入路由配置
import "/public/css/index.css";
import "/public/css/iconfont.css";
import globalMixin from './mixins/globalMixin'; // 引入Mixins
import store from './store';
import "/public/css/oppoSans.css";
import '../public/bootstrap-5.0.2-dist/css/bootstrap.min.css';
import 'bootstrap';
import 'jquery/dist/jquery.min.js';
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueI18n from 'vue-i18n';
import English from './i18n/en';
import German from './i18n/de';
import App from './App.vue';
import PageDashboard from "./components/PageDashboard.vue"
import ComTable from "./components/ComTable.vue"
import locale from 'element-ui/lib/locale/lang/en'

// =============
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import './assets/custom-styles.css'; // 引入自定义样式文件
// =============
// 引入echarts  
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.prototype.$residentialProductId = 'ca1b7e06-7b39-436b-b538-bafc9feb37de';
Vue.prototype.$commercialProductId = 'cd02c153-69e8-4b2e-aef7-f27c0bef917b';
Vue.component("PageDashboard", PageDashboard)
Vue.component("ComTable", ComTable)

window.toastr = toastr;

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(ElementUI, { locale })
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAsjDyAQFKHrRgU1ceGWNVTXjNdMZsv7ts', // 替换为你的 Google Maps API 密钥
    libraries: 'places,geometry'
  }
});

// 导入过滤器
import '@/utils/filter';

// 注册全局 mixin
Vue.mixin(globalMixin);

Vue.use(ElementUI);
Vue.use(CKEditor);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const messages = {
  en: English,
  de: German
};

const i18n = new VueI18n({
  locale: 'en', // 默认语言设置为 'en'
  messages
});

new Vue({
  i18n,
  el: '#app',
  router, // 使用路由
  store, // 使用vuex
  render: h => h(App),
}).$mount('#app');