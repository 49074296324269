export default {
  message: {
    // headerList
    en: 'Englisch',
    de: 'Deutsch',
    logout: 'Abmelden',
    One_StopSolarProductsIntegrator: 'Ich bin die Einzige Anlaufstelle für Solarkollektion',
    Get_A_Quote: "Angebote bekommen",
    // index
    Four_Steps_for_a_Successful_Project: "Vier stufen des erfolgreichen projekts",
    PROJECT_COMPLETION_TIMELINE: "Zeitplan für die fertigstellung des projekts",
    Residential_business_Days: "Wohnung: 3-5 Werktage",
    Business_Days: "7-10 Werktage",
    Step_1: "Schritt 1",
    Step_2: "Schritt 2",
    Step_3: "Schritt 3",
    Step_4: "Schritt 4",
    step_1_desc: "Berater",
    step_2_desc: "Personalisierte lösungen entwerfen",
    step_3_desc: "Online bestellen",
    step_4_desc: "Bau und installation",
    Read_More_About_How_to_Purchase: "Es geht mehr um den kauf",
    All_In_One_Solutions: "Integrierte lösung",
    minutes_is_the_time_span_for_sunlight_to_reach_the_blue_planet: "8 minuten ist die zeit, in der die sonne den blauen planeten erreicht",
    At_this_moment_everything_is_reviving_and_thriving: "Sieht alles wieder gut aus, hier und jetzt",
    The_innovation_of_light_and_electricity_is_endless: "Licht und elektrizität sind grenzenlos",
    SolarEdgeThe_birth_and_popularization_of_new_materials_have_brought_smart_energy_projects_into_thousands_of_households: "Mit der einführung und verbreitung neuer materialien, die intelligente energieprojekte in millionen Von häusern entstehen lassen",
    Photovoltaics_are_the_future: " Photovoltaik ist die zukunft",
    Sevice_Process: "Reihenfolge der dienstleistungen",
    Solar_Consulting_Expertise: "Um sich auf diesem gebiet zu beraten",
    In_addition_to_its_off_the_shelf: "Neben seinem vorbestellten produkt bietet kleingeld auch maßgefertigte lösungen an, die den exklusiven bedürfnissen eines jeden kunden gerecht werden.",
    Read_More: "Lies mehr",
    Door_to_Door_Survey:"Die foto-umfrage Von tür zu tür",
    This_ensures_that_the_preliminary:"Um sicherzustellen, dass die vorläufigen entwürfe durchführbar sind, indem sie die messung bestätigen und weitere für das planprojekt erforderliche informationen erhalten.",
    Install_Monitoring:"Installieren und überwachen",
    In_addition_to_its_off_the_shelf_productsL:"Neben den angefertigten produkten stellt soedge speziell angefertigte lösungen für den jeweiligen bedarf zur verfügung Einzigartige bedürfnisse des kunden.",
    // fiiter  底部翻译内容样式
    Contact_Us:"Kontakt",
    charging_station:"Leicht solarenergie Von, kundenspezifische beratung, kundenspezifische produktlosungen und vollstandige lebenszyklus o&m - dienste.",
    Easy_Solar_Supply:"Ja, solarenergie für dich.",
    More_Power_Of_Light_Keep_Your_Home_Always_Bright:"Mehr licht wird euer zuhause erleuchten",
    Call_Us_Anytime:"Ruf uns an, sooft du willst",
    Call_Us_Phone:"+919, 741, 1511",
    Company:"Inc",
    About_Us:"Um uns",
    Company_Introduction:"Seien sie kein",
    Legal_Provisions:"Das gesetz schreibt vor",
    Problem_Feedback:"Rückmeldung zur frage",
    Friendship_link:"Durch freundschaft.",
    Renewable_Energy:"Erneuerbare energien",
    PV_Energy:"Photovoltaik.",
    Installation_Manual:"Handbuch für installation",
    Monitoring_System:"Um was für ein überwachungssystem handelt es sich?",
    Our_Email:"Ich habe eine mail bekommen",
    Get_the_latest_news_and_discounts_in_your_inbox:"Zugriff auf aktuelle informationen und rabatte in ihren posteingang",
    Submits:"Eingereicht werden",
    Copyright:"Urheberrechte.",
    Easy_Solar_Supply_All_rights_reserved:"2024. Easy y, wir haben die ganzen rechte!",
    // residential
    Solution_for_residential_energy_storage_system:"Das energiespeise-system in der siedlung zu lösen",
    The_integrated_solution_for_solar_energy_storage:"Integrierte lösungen für das aufladen Von solarspeicher auf dächern im haus Oder für photovoltaische anlagen, die üblicherweise mit einem 480v-hochspannungsnetz verbunden sind, lassen sich sowohl selbst anwenden als auch die bestehende verbindung herstellen. Die kapazität der pv-modulen beträgt 25 bis 150kW und das sind 10 bis 50 kilo-speicherbatterien und rotoren (elektrizität)",
    Home_PV_Green_Power_Packages:"Photovoltaik für haushalte. Es läuft einiges",
    gird_PPVs_Sstorage_Ccharging:"G: elektrisches netz P: photovoltaik: speicherplatz C: aufladen",
    Annual_Power_Generation:"Jahr der stromerzeugung:",
    Construction_Mode_Optional:"Option zum bau:",
    Pv_Panels_Optional:"Für wählen wählen pv-komponenten",
    Rated_Grid_Voltage:"Spannung des wirklichen netzes:",
    Installation_area:"Zu installierte bereiche.",
    Number_of_blocks:"Durchmesser:",
    Residential_power_plant_solution:"Die für die residenz gebaut wurde",
    Our_intelligent_components:"Als unser geistiges modell",
    Maximize_Every_Ray_Of_Sunshine:"Und jeden strahl maximieren",
    EasySolar_Home_optimizes_each_solar_panel_individually:"Easyjet wurde auf jede solarkollektion separat ausgerichtet, was selbst an nachtzeiten die maximale leistung afp gewährleistet.",
    Residential_Inverters:"Der wohnungflicker",
    Our_smart_energy_managers_optimize_the_home:"Unser intelligente energiemanagement gestaltet den energiestrom in den haushalten so optimiert wie möglich und erhöht tag und nacht die solarproduktion, -speicherung und -verbrauch so maximieren wie möglich.",
    Storage_And_Backup:"Ein signal. Stopp und sicherung.",
    Our_highly_efficient_DCcoupled_Batteries:"Unsere hocheffizienten direktenergiekuppler speisen die batterie mit überschüssiger sonnenenergie ab, die für die private stromzufuhr hoch Oder nachts reicht. Als unsere back-ups installiert sind, liefern sie zuverlässige ersatzbatterien.",
    Home_EV_Charger:"Ladegeräte für den haushalt, elektrisch",
    Home_EV_charger_is_a_level_two_charger_that_offers_the_flexibility_to_function :"Batterieladegeräte für den haushalt sind plup 2, die auf flexible weise Laufen und auch fehlerfrei mit dem easyk-haushaltszentrum Laufen können. Die laden können eine geschwindigkeit Von 25 prozent afp erreichen, einsatz Von blitzlampen und preiswertem solarenergie.",
    Smart_Control_At_Your_Fingertips:"Intelligenz lässt sich nur in ihren fingerspitzen messen",
    Take_complete_control_of_your_home_energy_ecosystem_with_our_user_friendly:"Und durch unsere benutzerfreundliche anwendung wird ihr energiesystem komplett gesteuert.Optimieren sie ihren energieverbrauch und überwachen sie ihre systemleistungen in echtzeit.",
    Save_More_Energy:"Spart viel mehr energie.",
    Cost_Saving:"Es sind reine kosten.",
    Save_electricity_cost_and_reduce_your_dependence_on_the_grid:"3. Sparen der strom und verringern die abhängigkeit vom netz.",
    Green_Power_With_Enhanced_Safety:"Grüne energie, mehr sicherheit",
    Enhanced_Safety:"Verstärkte sicherheit",
    Meet_the_higher_safety_standard_ensure_personnel_and_property_safety:"9. Höhere sicherheitsstandards erfüllen, um die sicherheit Von personen und eigentum gewährleisten zu können.",
    Your_Energy:"Ihre kraft.",
    Management_Assistant:"Ich assistant manager.",
    Your_Smart_Energy_Home:"Das heim der intelligenten energie",
    Predict_your_system_generation_and_consumption:"Die prognose Von kapazitäten und verbrauch ihrer systeme zur sicherstellung eines besseren energiemanagements erfordert.",
    Elegant_Design_To_Brighten_Your_Home:"Elegantes entwerfen, um euer zuhause zu erleuchten",
    Less_Is_More:"Weniger ist mehr",
    Modern_design_fits_with_your_home_style_with_elegance:"Moderne architektur lässt sich durch ihren persönlichen stil ergänzen.",
    No_Worry_about_Power_Blackout_Enjoy_Every_Moment:"Achten sie nicht auf den stromausfall, und genießen sie jeden moment",
    Home_Battery_Backup:"Netzbatterie für den haushalt",
    Enjoy_every_moment_without_worrying_about_power_blackout:"Jeden moment genießen und keine angst vor dem stromausfall haben.",
     // Commercial
     Commercial_and_Industrial_Solar_System:"Solaranlagen für industrie und wirtschaft",
     Installing_a_rooftop_solar_system_on_your:"Ein solarsystem auf dem dach ihres geschäftsgebäudes könnte die energiekosten beträchtlich senken, ihnen helfen, zukünftige energieunsicherheit zu vermeiden und sie zeigen in der öffentlichkeit auch, dass sie sich für die umsetzung der nachhaltigen entwicklungsziele ihrer unternehmen engagieren. Technische lösungen für nukleare technologien stellen einen entscheidenden unterschied in den kommerziellen spielarten dar. All diese sind so entworfen, um mehr energie aus jedem einzelnen batteriestrang zu erzeugen, der einen höheren investitionsertrag und höhere rentabilität bringt als das herkömmliche solarstromsystem. Achten sie auf geschäftskontinuität.",
     Commercial_PV_Green_Power_Packages:"Nur für photovoltaik. Es läuft nichts",
     Grepresents_gird_Prepresents_PVs_Srepresents_storage_Crepresents_charging:"G: für den netzeffekt sorgen; für photovoltaische verhältnisse: gaben für einen abschuss aus",
     Annual_Power_Generation: "Jahr der stromerzeugung: ",
     Construction_Mode_Optional: "Option zum bau:",
     Pv_Panels_Optional: "Aber kaff nimmt sich farben vor:",
     Rated_Grid_Voltage: "Spannung des wirklichen netzes:",
     Installation_area: "Regiment nr. 1:",
     Number_of_blocks: "Durchmesser:",
     Commercial_power_plant_solution: "Die kommerzielle blackouts zu lösen",
     Our_intelligent_components: "Als unser geistiges modell",
     Smart_Module_Controller:"Mikromodul steuerung",
     A_Wonderful_Option_for_Your_Business:"Eine gute wahl für ihr geschäft. Und beginnen sie die fläche des dachs für saubere energie zu nutzen. Objekteurin überwacht den pv-modulen in echtzeit, um den richtigen sichtpunkt und den richtigen vertrauen zu erzeugen.",
     Your_Magic_PV_Box:"Nur ihre magischen pv-box. Aber es gibt viele sicherheitssysteme, die das geschäft und das vermögen schützen.",
     Smart_Storage_Batteries:"akkumulatoren",
     Your_Reliable_Power_Bank_Energy_storage:"Ich hole deinen arsch zurück. Einlagerungs - und energiesysteme speichern energie für den nachwärmischen Oder regentlichen einsatz, ohne die vorhandene energie zu verbrennen.",
     Commercial_Fast_Charging:"Bank behält es auch zurück.",
     While_maximizing_the_profitability_of_parking_areas_is_a_primary_goal:"Während die maximierung der rentabilität des parkplatzes ein wichtiges ziel ist, ist es wichtig, die sicherheit Von personen und material zu gewährleisten, die in unmittelbarer nähe des parksystems arbeiten. Unsere pv-systeme sind voll integriert, sodass es den strengsten sicherheitskriterien entspricht und die eingebaute sicherheit erfüllt.",
     Property_On_The_Hand:"Das wird dir aber nicht gerecht.",
     Combining_with_Smart_PV_controller:"Was ist das? Träume für dich. Träume für dich.",
     Unlock_the_Potentials_of_Your_Rooftop:"Macht euer dach auf",
     Utilize_Your_Rooftop:"Benutz dein dach",
     Start_to_make_full_use_of_the_roof_area_and_produce_clean_energy:"Und beginnen sie die fläche des dachs für saubere energie zu nutzen.",
     Generate_More_Store_More_Earn_More:"So dass sie mehr erzeugen, speichern, mehr einbringen",
     Energize_Your_Earnings:"Regt eure einnahmen an",
     Supply_emergent_power_when_power_outage:"Einbindung der ESS, bereitstellung Von notstrom bei stromausfällen, um die stromversorgung zu gewährleisten",
     Ensure_Your_Safety_With_Leading_Protection:"Und sorgt für sicherheit und führung",
     Industry_leading_Protection:"Der sektor geht voran",
     From_PV_to_ESS_System_Level_Safety_Protection_to_ensure_personal_and_property_safety:"Von photovoltaik bis zum trifft es auf systemische sicherheit, um personen und besitztümer zu schützen.",
     Superior_Quality_Built_to_Last_for_Decades:"Hochwertige, haltbare eigenschaften",
     Excellent_Quality_Reliability:"Qualität und zuverlässigkeit zu bewerten",
     Solar_System_will_continue_to_produce_clean_energy_for_your_company_for_decades:"Und in den nächsten jahrzehnten wird elektroenergie für ihre firma frei gemacht werden.",
     AI_Boost_Empowers_You_with_Simple_OM:"Das hat AI Boost mir gesagt, dass es dir einfacher ist",
     Guarantees_You_Simple_OM:"Mann, du siehst toll aus, mann",
     Built_Smart_Brings_You_Simple_OM_AI_Boost_Brings_You_Simple_OM:"Mit eingebauten intelligenz sehen wir keine einfachen OM, sondern zwei uhr am ende.",
    // Design
    Propose_all_your_solar_sales_with_Easy_Solar:"Empfiehlt allen kunden solarenergie. Was sie mit solarenergie machen",
    We_are_capable_of_delivering_the_Solar_Sales_proposal_in_just:"* wir können innerhalb Von zwei stunden nach antrag einen solarbauplan bereitstellen. Darin wird die gesamtheit der einsparungen dargestellt, die in jedem projekt (wohnung Oder geschäft) unter berücksichtigung Von hindernissen wie lüftungsschächten, lüftungsschächten, lüftungsschächten und kaminen berücksichtigt werden.",
    Sales_Proposal:"Vertriebsrat, mann.",
    Site_Survey:"Live analyse",
    Plan_Set:"Programmieren.",
    Engineering_Review:"Baukontrollen. - check.",
    PTO:"Von der amerikanischen patentbehörde",
    Permitting:"Erlaubt.",
    Get_detailed_solar_sales_proposals_created_using:"Google shares und GoogleMaps bilder Von LlDAR und GoogleMaps zu asset assets erstellen. Die empfehlungen werden ihnen helfen, ihre verkaufsmethoden selbstbewusst zu präsentieren und die vorteile einer solaranlage effektiv mit dem projektbesitzer zu diskutieren. Der designs kann ihren spezifikationen angepasst werden und wir können sie problemlos mit ihrer bisherigen solarsoftware integrieren.",
    Get_Sales_Proposal_within_hours:" Innerhalb Von zwei stunden nach pfeifen einen entwurf *, der die einsparungen in jedem projekt (ob als wohn - Oder handelsprojekt) anzeigt, unter berücksichtigung der lüftungsschächte, lüftungsschächte, schornsteine usw. Der entwurf zeigt, wie die solarzellen in ihrem haus entstehen.",
    sign_up_now:"Unterschreiben sie die liste sofort.",
    Our_Detailed_Report_Includes:"In unseren ausführlichen berichten ist",
    With_a_detailed_Solar_Sales_proposal_created_using_LIDAR:"Indem sie mit laser, radar und bildern Von google maps detaillierte vorschläge für den verkauf Von solarenergie erstellen, können sie auf jede verkaufsrede vertrauen.",
    Site_Assessments:"Einschätzung der website",
    LIDAR_Overlay:"3d-laser ueber den stand",
    Aurora_Shade_Report:"Aurora widow meldet sich",
    Street_View_3D_Model:"Straßenbilder 3D modell",
    Shade_Stats:"Die daten im schatten",
    Analysis:"Analyse.",
    Maximum_PV_System_Size:"Maximale photorengröße",
    Roof_Slope_and_Azimuth:"Das gefälle auf dem dach und der quadrat",
    String_Sizing_Electrical_Characteristics:"Saitengröße und elektrische eigenschaften",
    Estimated_Power_Production:"Erwartete stromerzeugung",
    Equipment_Description:"Beschreibung des geräts.",
    Installation_Area_Length_and_Width:"Größe (länge und breite) der installation",
    // Purchase
    Four_Steps_To_A_Successful_Purchase:"Vier stufen, um den kauf erfolgreich zu machen",
    Contact_Us:"Um uns zu kontaktieren.",//重复
    Try_our_easy_solar_calculator:"Versuchen sie den einfachen solartaschenrechner! Schicken sie einfach ihre stromrechnung rauf. Unser system wird ihnen automatisch die besten solarmodul-pakete empfehlen. Bitte geben sie uns ihre e-mail Oder telefonnummer, damit wir mit ihnen kontakt aufnehmen können.",
    solar_calculator:"Der solartaschenrechner.",
    Complete_Solar_Consulting_Expertise:"Zur solo-beratung fähig sind",
    Site_and_Sunlight_Analysis:"Abtastung und analyse der strahlungssignatur",
    Wiring_Size_Calculations:"Korsettengröße berechnet",
    System_Capacity_Estimation:"Systemkapazität schätzen.",
    Grid_Connection_Design:"Design für vernetzte netze",
    Value_Optimization:"Optimierung der werte",
    Ready_to_see_how_solar_can_power_your_home:"Ok, möchtest du sehen, wie die solarenergie dein haus mit strom versorgt? ",
    Get_a_personalized_proposal_within_24_hours:"Innerhalb Von 24 stunden stunden stunden stunden stunden stunden stunden stunden persönlichen ratschlägen unterziehen. In unserem bericht wird erklärt, wie viel sonnenkraft durch einen wechsel zu einem kamin, einer schächte Oder einem dach einsparen kann. Und wir komen ein visualisives solarpanel Von dir zu hause.",
    Heres_what_youll_find_in_your_free_solar_proposal:"Hier ist was sie in dem angebot für kostenlose solarenergie finden werden:",
    Solar_Panel_Capacity_Discover:"Solarmodulaii: wie viel strom kann dein dach erzeugen?",
    Optimized_Roof_Layout_Well_consider_your_roof:"Das bild ist optimal: die neigung des gebäudes und seine sonneneinstrahlung verstärken sich, um die optimale konstruktion zu ermöglichen",
    Smart_System_Design_Learn_about_the_wiring:"Intelligente system-design: das verständnis Von stromleitungen und elektrischen bauelementen, die ihren haushalt versorgen sollen.",
    Estimated_Energy_Production:"Geschätzte energieproduktion: schauen sie an, wie viel strom ihre solarkollektoren pro jahr erzeugen.",
    Equipment_Details_Get_a_breakdown_of_the_high:"Details über die geräte: geben sie uns detaillierte informationen zu den instrumenten und leisten sie einen überblick",
    Clear_Installation_Plan_Understand_exactly:"Installieren sie gründlich: eine genaue vorstellung, wie viel platz die flächen auf dem dach einnehmen sollen",
    Our_Solar_Design_Service:"Eine solaranlage?",
    Upon_receiving_an_order_we_promptly_review_local_regulations:"Wir überprüfen sofort nach eingang der mandaten die örtlichen vorschriften und führen eine vorabanalyse der anlage und der bereich sonnenschein durch, um sicherzustellen, dass ihre neue solaranlage effizient gestaltet ist. Während der gesamten zeit haben wir Engen kontakt mit ihnen, den örtlichen inspektoren und den produktherstellern unterhalten, um sicherzustellen, dass ihr projekt reibungslos, rasch und unter kontrolle gebracht wird.",
    // 
    Complete_Solar_Energy_Design_Suite:"Eine vollständige solaranlage: ",
    Detailed_Site_Layout_and_Plans:"Der gebäudeplan und jeder plan",
    Structural_Blueprints:"Strukturzeichnungen.",
    Single_Line_Electrical_Diagrams:"Einfacher elektroplan",
    Three_Line_Electrical_Diagrams:"Der dreidrahtseite eeg",
    Safety_Labeling_Plans:"Etiketten für sicherheitsschilder",
    Our_expert_team_handles_every_detail:"Unser expertenteam beschäftigt sich mit jeder einzelheit:",
    Engineering_Design_We_create_a_customized_solar_plan_optimized:"Technik und design: wir schaffen maßgefertigte solarenergielösungen, die ihrem dach und ihren energieverbrauch entsprechen.",
    Collaborative_Design_Reviews: "Design juror: wir arbeiten eng mit ihnen zusammen, um sicherzustellen, dass das design ihren erwartungen gerecht wird.",
    Detailed_Design_Blueprints:"Blaupausen: es gibt klare unterlagen für zukünftige beobachtungen und wartung.",
    Comprehensive_Bill_of_Materials:"Umfassende materialliste: sie können die durchsichtige anwendung aller komponenten des systems durchlaufen",
    Seamless_Permitting_Approvals:"Dank dank der günstigen navigation können wir ihnen zeit und mühe ersparen.",
    Complete_Communication:'Umfassende kommunikation: wir informieren während der installation alle parteien.',
    Post_Installation_Support:"Nach der installation",
    Upon_receiving_an_order:"Wir überprüfen sofort nach eingang der mandaten die örtlichen vorschriften und führen eine vorabanalyse der anlage und der bereich sonnenschein durch, um sicherzustellen, dass ihre neue solaranlage effizient gestaltet ist. Während der gesamten zeit haben wir Engen kontakt mit ihnen, den örtlichen inspektoren und den produktherstellern unterhalten, um sicherzustellen, dass ihr projekt reibungslos, rasch und unter kontrolle gebracht wird.",
    Reliable_Performance_Backed_by_over_30_years_of_Experience:"Auf einer verlässlichen leistung, die über 30 jahre erfahrung unterstützt wird",
    Our_experts_understand_the_importance_of_ongoing_system_health:"Unsere experten verstehen die bedeutung der kontinuierlichen systemgesundheit. Unser bekenntnis zur qualität geht über die installation hinaus hier sorgen wir dafür, dass ihre solaranlage die gewünschten sauberen energie liefert.",
    Effortless_Monitoring_Control:"Vor und zurück",
    Real_Time_System_Insights:"Systemeinsichten: der zugriff auf die systemleistungen wird über eine benutzerfreundliche online-überwachungssystem ermöglicht",
    Intelligent_Monitoring:'24. 7. Intelligente überwachung: unser schlaues system konzentriert sich auf die sonnenkollektoren, die sie prüfen und sie automatisch auf mögliche probleme aufmerksam machen.',
    Remote_System_Management:"Verwaltung eines fernrechners: erlauben sie sich die verbesserung der entfernung und die änderung der parameter, indem sie dafür sorgen, dass ihr system optimal gestaltet wird",
    one:"01",
    two:"02",
    three:"03",
    four:"04",
    five:"05",
    six:"06",
    // Service  
    Complete_Solar_Consulting_Expertise:"Zur solo-beratung fähig sind",
    Try_our_easy_solar_calculator:"Versuchen sie den einfachen solartaschenrechner! Schicken sie einfach ihre stromrechnung rauf. Unser system wird ihnen automatisch die besten solarmodul-pakete empfehlen. Hinterlassen sie bitte eine e-mail Oder telefonnummer, damit wir mit ihnen kontakt aufnehmen können.",
    Solar_Calculator:"Der solartaschenrechner.",
    Find_a_Consultant:"Von einem berater.",
    Door_to_door_survey:"Die foto-umfrage Von tür zu tür",
    Get_a_personalized_proposal_within_24_hours:"Innerhalb Von 24 stunden stunden stunden stunden stunden stunden stunden stunden persönlichen ratschlägen unterziehen. In unserem bericht wird erklärt, wie viel sonnenkraft durch einen wechsel zu einem kamin, einer schächte Oder einem dach einsparen kann. Und wir komen ein visualisives solarpanel Von dir zu hause.",
    // Read_More: "Read More",//重复
    Design_Package_Recommendations:"Design und verpackung empfehlen",
    At_EasySolar_we_know_the_importance_of_expertise:"Hier bei easyl Wissen wir, wie wichtig das fachwissen ist, das jede familie braucht, um ein update zu erreichen. Afp: afp Die wahl der ausgewählten solarmodulatoren ist ein wichtiger faktor für leistung und energieeinsparung. Wir arbeiten nahtlos daran, einen schritt nach vorn zu ermöglichen.",
    Permit_Installation:"Lizenzen und installation",
    Upon_receiving_an_order:"Wir überprüfen sofort nach eingang der aufträge die örtlichen vorschriften und führen eine vorabanalyse der anlage und der sonnenbereiche durch, um sicherzustellen, dass ihr neues solarstromanlage effizient gestaltet wird. Während der gesamten zeit haben wir Engen kontakt mit ihnen, den örtlichen inspektoren und den produktherstellern unterhalten, um sicherzustellen, dass ihr projekt reibungslos, rasch und unter kontrolle gebracht wird.",
    With_EasySolar:"Mit easyl können sie sicher sein, dass die installieren der solarpaneele in den händen eines glaubwürdigen und erfahrenen teams liegen. Afp Während sie die vorteile sauberer und nachhaltiger energie nutzen, lassen sie uns in die lösung dieser komplexen probleme gehen.",
    Effortless_Monitoring_Control:"Vor und zurück",
    // Real_Time_System_Insights:"Systemeinsichten: der zugriff auf die systemleistungen wird über eine benutzerfreundliche online-überwachungssystem ermöglicht",
    // 重复
    // Intelligent_Monitoring:"24. 7. Intelligente überwachung: unser schlaues system konzentriert sich auf die sonnenkollektoren, die sie prüfen und sie automatisch auf mögliche probleme aufmerksam machen.",
    // Remote_System_Management:"Verwaltung eines fernrechners: erlauben sie sich die verbesserung der entfernung und die änderung der parameter, indem sie dafür sorgen, dass ihr system optimal gestaltet wird",
  }
}
// Service Purchase