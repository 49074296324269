<template>
  <div>
    <div class="contactUs-section">
      <div class="container">
        <div class="section-content">
          <div class="section-title">
            {{ $t("message.Contact_Us") }}
          </div>
          <div class="contact-desc">
            {{ $t("message.charging_station") }}
          </div>
          <div class="section-btn">
            <a href="/contactForm">
              <div class="contact-btn">
                {{ $t("message.Contact_Us") }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="footer-logo">
              <h3>
                <a href="" style="color: #fff">
                  {{ $t("message.Easy_Solar_Supply") }}
                </a>
              </h3>
            </div>
            <div class="footer-widget-desc">
              <p style="color: #fff">
                {{
                  $t("message.More_Power_Of_Light_Keep_Your_Home_Always_Bright")
                }}
              </p>
            </div>
            <div class="solar-conatct-box">
              <div class="solar-contact-icon">
                <img src="/img/call-icon.png" alt="icon" />
              </div>
              <div class="solar-contact-content">
                <p>
                  {{ $t("message.Call_Us_Anytime") }}
                </p>
                <a href="tel:+325 25148 5874">
                  {{ $t("message.Call_Us_Phone") }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="footer-widget-content">
              <div class="footer-widget-title">
                <h4>
                  {{ $t("message.Company") }}
                </h4>
              </div>
              <div class="footer-widget-menu">
                <ul>
                  <li>
                    <a href="#">{{ $t("message.About_Us") }}</a>
                  </li>
                  <li>
                    <a href="#">{{ $t("message.Company_Introduction") }} </a>
                  </li>
                  <li>
                    <a href="#">{{ $t("message.Legal_Provisions") }} </a>
                  </li>
                  <li>
                    <a href="#">{{ $t("message.Problem_Feedback") }} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-widget-content">
              <div class="footer-widget-title">
                <h4>{{ $t("message.Friendship_link") }}</h4>
              </div>
              <div class="footer-widget-menu">
                <ul>
                  <li>
                    <a href="#">{{ $t("message.Renewable_Energy") }}</a>
                  </li>
                  <li>
                    <a href="#">{{ $t("message.PV_Energy") }} </a>
                  </li>
                  <li>
                    <a href="#">{{ $t("message.Installation_Manual") }} </a>
                  </li>
                  <li>
                    <a href="#">{{ $t("message.Monitoring_System") }} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-widget-content">
              <div class="footer-widget-title">
                <h4>
                  {{ $t("message.Our_Email") }}
                </h4>
              </div>
              <div class="footer-widget-desc-2">
                <p>
                  {{
                    $t(
                      "message.Get_the_latest_news_and_discounts_in_your_inbox"
                    )
                  }}
                </p>
              </div>
              <form action="#">
                <div class="newsletter-box">
                  <input
                    type="Email"
                    name="Email"
                    placeholder="Your Email"
                    required=""
                  />
                </div>
                <div class="newsletter-submit-button">
                  <button type="submit">
                    {{ $t("message.Submits") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="copyright-area">
              <p>
                {{ $t("message.Copyright") }} &copy;
                {{ $t("message.Easy_Solar_Supply_All_rights_reserved")
                }}<a target="_blank" href=""></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCookieVisible" class="footer_Cookie">
      <button @click="closeCookie" class="close">
        <i class="iconfont icon-guanbi"></i>
      </button>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            We use cookies and similar technologies to personalize content and
            ads and analyze our traffic. By browsing our site or by selecting
            "<i class="iconfont icon-guanbi"></i>", you agree to our use of
            cookies and our sharing of information with our partners. View the
            Lenovo <u>Website Privacy Statement.</u> Visit our
            <u @click="dialogVisible = true">Cookie Consent Tool</u> to manage
            your preferences. <u>Click Here </u>to Reject All non-essential
            cookies.
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible" width="50%">
        <div class="dialog_box">
          <div><button class="easySolarSupply">easySolarSupply</button></div>
          <div class="title">Information We Collect About You</div>
          <div class="content">
            We want to be transparent about the data we and our partners collect
            and how we use it, so you can best exercise control over your
            personal data. For more information, please see our Privacy Policy.
          </div>
          <div class="title">Information Our Partners Collect</div>
          <div class="content">
            We use the following partners to better improve your overall web
            browsing experience. They use cookies and other mechanisms to
            connect you with your social networks and tailor advertising to
            better match your interests. You can elect to opt-out of this
            information collection by unticking the boxes below.
          </div>
          <div class="categories">
            <span>Categories</span>
            <span class="allowed"
              >2 of 2 allowed
              <el-switch
                class="el_switch"
                v-model="allowed"
                active-color="#285d50"
                inactive-color="#ff4949"
              ></el-switch>
            </span>
          </div>
          <div class="advertising">
            <p class="advertising_box">
              <span><u class="advertising_u">Advertising</u></span
              ><span>
                <el-switch
                  class="el_switch"
                  v-model="advertising"
                  active-color="#285d50"
                  inactive-color="#ff4949"
                ></el-switch>
              </span>
            </p>
            <p>
              Our advertising partners allow us to serve you relevant
              advertising across the web, based on your interests. They can also
              be used to limit the number of times you see an advertisment as
              well as help measure the effectiveness of our advertising
              campaigns. These partners may collect information about you such
              as your browser information, location, and/or IP address. Click on
              one of the partner names to learn more.
            </p>
          </div>
          <div class="advertising">
            <p class="advertising_box">
              <span><u class="advertising_u">Analytics</u></span
              ><span>
                <el-switch
                  class="el_switch"
                  v-model="analytics"
                  active-color="#285d50"
                  inactive-color="#ff4949"
                ></el-switch>
              </span>
            </p>
            <p>
              We use analytics to improve our website by better understanding
              how often users visit the site, what pages they visit most, and
              how long they spend on our site. We'll rely on cookies and
              third-party partners to track these actions and behaviors. Click
              on one of the partner names to learn more.
            </p>
          </div>
          <div class="advertising">
            <p class="advertising_box">
              <span><u class="advertising_u">Essential</u></span
              ><span class="required"> Required </span>
            </p>
            <p>
              We rely on cookies, javascript, and other web technologies to
              serve key—or essential—elements on the site. This may include
              things like your language preferences or server-based cookies
              intended to keep our site running and operational. If disabled,
              your site experience will likely be impacted.
            </p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="optOut" @click="handleOptOut">Opt Out</el-button>
          <el-button
            class="cancel"
            type="primary"
            @click="dialogVisible = false"
            >Cancel</el-button
          >
        </span>
      </el-dialog>
    </div>
    <el-dialog
      :visible.sync="newDialogVisible"
      width="25%">
      <button class="offer_easySolarSupply">easySolarSupply</button>
      <div class="offer_box">
         <div>ENTER YOUR EMAIL TO UNLOCK</div>
         <div>Up to $100 off*</div>
         <div>your Address</div>
         <!-- <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
          <el-form-item>
            <p>Email Address</p>
            <div style="display:flex;">
              <el-input v-model.number="numberValidateForm.email" autocomplete="off"></el-input>
            <select  id="" class="select_states">
               <option value="United States">United States</option>
               <option value="United States">United States</option>
            </select>
            </div>
          </el-form-item>
          <el-form-item>
            <p>Name</p>
            <el-input v-model.number="numberValidateForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('numberValidateForm')">Unlock Offer</el-button>
            <el-button @click="resetForm('numberValidateForm')">重置</el-button>
          </el-form-item>
        </el-form> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="newDialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
export default {
  components: {},
  props: {},
  data() {
    return {
      isCookieVisible: true,
      dialogVisible: false,
      newDialogVisible:false,
      allowed: true,
      advertising: true,
      analytics: true,
      numberValidateForm: {
          email: '',
          name:""
        }
    };
  },
  computed: {},
    methods: {
      handleOptOut() {
        Cookies.set('allowed', this.allowed);
        Cookies.set('advertising', this.advertising);
        Cookies.set('analytics', this.analytics);
        // 关闭对话框
        this.dialogVisible = false;
      },
    closeCookie() {
      this.isCookieVisible = false; // 关闭当前对话框
      localStorage.setItem('cookieClosed', 'false');
      // setTimeout(() => {
      //   this.newDialogVisible = true; // 5秒后显示新的对话框
      // }, 3000);
    
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
    created() {
      if (localStorage.getItem("cookieClosed")) {
        this.isCookieVisible = false;
      }
    },
    mounted() {},

};
</script>


<style scoped>
.select_states{
  padding: 10px;
}
.offer_easySolarSupply{
  color: white;
  background: #7db53b;
  border: 1px solid #7f7f7f;
  padding: 10px;
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 0;
}
.optOut {
  background: #294e95;
  color: white;
}
.cancel {
  background: white;
  border: 1px solid #294e95;
  color: #294e95;
}
.required {
  color: black;
  font-weight: 500;
}
.advertising_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-top: 1px solid #c1c1c1;
  height: 45px;
}
.categories {
  display: flex;
  width: 100%;
  background: #294e95;
  border-radius: 3px;
  color: white;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #7f7f7f;
}
.allowed,
.el_switch {
  display: flex;
  align-items: center;
}
.el_switch {
  margin: 13px 5px 0 5px;
}
.easySolarSupply {
  color: white;
  background: #7db53b;
  border: 1px solid #7f7f7f;
  padding: 15px;
  font-size: 1.2rem;
}
.dialog_box {
  height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  overflow: auto;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #c1c1c1;
}
.dialog_box div {
  margin-bottom: 15px;
}
.content {
  font-size: 12px;
}
.close {
  position: absolute;
  right: 10px;
  top: 5px;
  border: 0;
}
.title {
  color: #575757;
  font-weight: 500;
  font-size: 1rem;
}
.footer_Cookie {
  display: flex;
  width: 100%;
  height: 70px;
  background: #f2f2f2;
  position: fixed;
  bottom: 0;
  align-items: center;
  font-size: 14px;
  z-index: 2001;
}
.contact-desc {
  font-size: 1.25rem;
}
.contactUs-section {
  height: 450px;
  background: url("../../public/img/contontUs_bg.webp") no-repeat center;
  background-size: cover;
  text-align: center;
  color: #ffffff;
}
.section-content {
  /* margin-top: 5rem; */
  height: 80%;
  padding: 6rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contactUs-section .section-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}
.section-btn {
  margin-top: 50px !important;
}
* a {
  color: inherit;
}
.contact-btn {
  width: 161px;
  height: 37px;
  margin: 1rem auto;
  border-radius: 5px;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white !important;
}
.footer-section {
  background: url("../../public/img/footer-bg.jpg") no-repeat center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 128px 0 18px;
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.footer-section .solar-conatct-box {
  padding: 24px 0 2rem 0;
}
.solar-conatct-box {
  display: -webkit-box;
  padding: 0 0 0 28px;
}
img {
  vertical-align: middle;
  border-style: none;
}
.solar-contact-content {
  color: white !important;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.footer-widget-content {
  margin-top: 10px;
}
.footer-widget-title {
  margin-bottom: 9px;
}
.footer-widget-title h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.footer-widget-menu ul {
  width: 100%;
}
.footer-widget-menu ul {
  width: 100%;
  height: 100%;
  padding: 0;
}
ul {
  margin: 0;
}
.footer-widget-menu ul li a {
  opacity: 0.8;
  font-size: 16px;
  line-height: 47px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Poppins";
  transition: 0.5s;
}
.footer-widget-content {
  margin-top: 10px;
}
.footer-widget-title {
  margin-bottom: 9px;
}
.footer-widget-title h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.footer-widget-menu ul {
  width: 100%;
}
.footer-widget-menu ul li a {
  opacity: 0.8;
  font-size: 16px;
  line-height: 47px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Poppins";
  transition: 0.5s;
}
.footer-widget-content {
  margin-top: 10px;
}
.footer-widget-desc-2 p {
  opacity: 0.8;
  color: #ffffff;
  line-height: 27px;
}
.newsletter-box {
  margin: 28px 0 19px;
}
.newsletter-box input {
  background: transparent;
  border: 1px solid #3a4034;
  height: 45px;
  outline: 0;
  padding: 0 22px;
  width: 100%;
  border-radius: 5px;
  color: white;
}
.newsletter-submit-button button {
  background: #7db53b;
  border: transparent;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  color: #fff;
}
.footer-section {
  background: url("../../public/img/footer-bg.jpg") no-repeat center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 128px 0 18px;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1340px;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.text-center {
  text-align: center !important;
}
.text-center u,
.advertising_u {
  color: #294e95;
  cursor: pointer;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area p {
  opacity: 0.8;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  margin-top: 25px;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
</style>
