import request from '@/utils/request';
// 登录
export function login(loginForm) {
  return request({
    url: '/user/login',
    method: 'post',
    data: loginForm
  })
}
// 手机短信登录 /user/phone/verification/login

export function generateCode(data) {
  return request({
    url: '/user/phone/verification',
    method: 'post',
    data
  })
}
// phone/verification/code 手机号注册校验
export function register(data) {
  return request({
    url: '/user/phone/verification/code',
    method: 'post',
    data
  })
}

// 通过邮箱获取验证码
export function getCodeByEmail(data) {
  return request({
    url: '/verify/generateCode',
    method: 'post',
    data
  })
}

// 获取地图坐标点信息
export function getMapInfo(data) {
  return request({
    url: '/product/get/contractor/list',
    method: 'post',
    data
  })
}
// 邮箱注册
export function postAddUser(data) {
  return request({
    url: '/user/add/user',
    method: 'post',
    data
  })
}
// 获取产品列表数据 
export function getProductList(data) {
  return request({
    url: '/product/get/product/list',
    method: 'get',
    data
  })
}
// 获取产品列表推荐 
export function getProductListByType(params) {
  return request({
    url: '/product/get/product/other/desc',
    method: 'get',
    params
  })
}
// 加入购物车//get params传参
export function addCart(data) {
  return request({
    url: '/order/add/cart',
    method: 'post',
    data
  })
}
// 发货详情
export function PostProductListByType(data) {
  return request({
    url: '/userShippingAddress/addUserShippingAddress',
    method: 'post',
    data
  })
}
// 获取套餐详情
export function GetProductByProductId(data) {
  return request({
    url: '/product/product_detail_list/acctes/inter/contractor/list',
    method: 'post',
    data
  })
}
// 账单地址
export function PostUserBillingAddress(data) {
  return request({
    url: '/userBillingAddress/addUserBillingAddress',
    method: 'post',
    data
  })
}
// 个人中心_我的订单
export function PostOrder(data) {
  return request({
    url: '/order/addOrder',
    method: 'post',
    data
  })
}
// userld 登录用户ID 显示商品 My Cart
export function getCart(data) {
  return request({
    url: '/order/get/cart',
    method: 'post',
    data
  })
}
// 购物车数量计算
// export function getCartNumber(data){
//   return request({
//     url: '/order/get/cart/number',
//     method: 'post',
//     data
//   })
// }
// remove.cart.product
// 删除购物车商品
export function removeCartProduct(data) {
  return request({
    url: '/order/remove/cart/product',
    method: 'post',
    data
  })
}
//survey/getSurveyListByUserId?userId=a18830fe-c2f9-4907-a4b6-41c35fa21537
export function getSurveyListByUserId(params) {
  return request({
    url: '/survey/getSurveyListByUserId',
    method: 'get',
    params: params
  })
}
//solution/get/solutions/list
// 获取解决方案列表
export function getSolutionList(params) {
  return request({
    url: '/solution/getSolutionList',
    method: 'get',
    params: params
  })
}

// http://192.168.1.106:8008/api/solution/get/solutions/desc
// 套餐页
export function getSolutionDesc(params) {
  return request({
    url: '/solution/get/solutions/desc',
    method: 'get',
    params: params
  })
}
// 获取用户信息
// https://developer.nrel.gov/api/pvwatts/v8.json
export function getPvWatts(data) {
  return request({
    url: '/pvwatts/v8.json',
    method: 'get',
    params: data
  })
}
// api_key: cAfoU2aSVaAIe5H3rxZSF4QIZCzPeodgHP9C8fHl //11个参数 现在有8个参数 少3个 一个是这个keykey就用他的
// http://192.168.1.106:8008/api/user/add/personal/config
export function addPersonalConfig(data) {
  return request({
    url: '/user/add/personal/config',
    method: 'post',
    data
  })
}
// /api/user/add/personal/config
export function postUserConfig(data) {
  return request({
    url: '/user/add/personal/config',
    method: 'post',
    params: data
  })
}

//http://192.168.1.106:8008/api/order/boa/stripe/play 支付 
export function postStripePlay(data) {
  return request({
    url: '/order/boa/stripe/play',
    method: 'post',
    data
  })
}

// /order/generate/order  创建订单 
export function postGenerateOrder(data) {
  return request({
    url: '/order/generate/order',
    method: 'post',
    data
  })
}
// /order/get/order/by/id
export function getOrderById(data) {
  return request({
    url: '/order/get/order/by/id',
    method: 'post',
    data
  })
}
// order/modified/order
export function modifiedOrder(data) {
  return request({
    url: '/order/modified/order',
    method: 'post',
    data
  })
}
// api/order/get/order/list/by/user/id 我的订单
export function getOrderListByUserId(data) {
  return request({
    url: '/order/get/order/list/by/user/id',
    method: 'post',
    data
  })
}
// http://0.0.0.0:8001/api/user/user_address_list/personal/config  收货地址
export function postUserAddressList(data) {
  return request({
    url: '/user/user_address_list/personal/config',
    method: 'post',
    data
  })
}
// http://0.0.0.0:8001/api/user/user_address_save/personal/config 收货地址添加
export function postUserAddressSave(data) {
  return request({
    url: '/user/user_address_save/personal/config',
    method: 'post',
    data
  })
}

// /user/phone/verification   手机号登录
export function postPhoneVerification(data) {
  return request({
    url: '/user/phone/verification/login',
    method: 'post',
    data
  })
}


// http://192.168.1.106:8008/api/order/post/addr/about/order  //账单地址
export function postAddrAboutOrder(data) {
  return request({
    url: '/order/post/addr/about/order',
    method: 'post',
    data
  })
}
// http://0.0.0.0:8001/api/user/user_address_save/personal/config
export function postUserAddressSave1(data) {
  return request({
    url: '/user/user_address_save/personal/config',
    method: 'post',
    data
  })
}
// http://192.168.1.106:8008/api/blog/home  导航栏 
export function getBlogHome(params) {
  return request({
    url: '/blog/home',
    method: 'get',
    params
  })
}

// http://192.168.1.106:8008/api/blog/router/path 路由数据动态化
export function postRouterPath(data) {
  return request({
    url: '/blog/router/path',
    method: 'post',
    data
  })
}
// http://192.168.1.106:8008/api/product/get/discount 商品折扣信息
export function getDiscount(params) {
  return request({
    url: '/product/get/discount',
    method: 'get',
    params
  })
}
// http://192.168.1.106:8008/api/product/get/full/minus  商品满减信息
export function getFullMinus(params) {
  return request({
    url: '/product/get/full/minus',
    method: 'get',
    params
  })
}

// http://192.168.1.106:8008/api/user/message/lookout   站内获取信息
export function postMessage(data) {
  return request({
    url: '/user/message/lookout',
    method: 'post',
    data
  })
}
// http://192.168.1.106:8008/api/user/message/lookout/read 站内已读
export function postMessageRead(data) {
  return request({
    url: '/user/message/lookout/read',
    method: 'post',
    data
  })
}
// http://192.168.1.66:8008/api/blog/article/get_article_content 通过id获取article内容
export function getArticleContent(params) {
  return request({
    url: '/blog/article/get_article_content',
    method: 'get',
    params
  })
}
// /api/blog/carousel/get_carousel_list 轮播图
export function postCarouselList(data) {
  return request({
    url: '/blog/carousel/get_carousel_list',
    method: 'post',
    data
  })
}

// customer_calculate/inter/nice/miss/z5y6q8dc 计算器
export function postCalculate(data) {
  return request({
    url: '/customer_calculate/inter/nice/miss/z5y6q8dc',
    method: 'post',
    data
  })
}
// solution_type: Residential
// roof_size: 550
// address: 409, Rockingham Street, Charlotte, North Carolina
// lat:35.2270869
// lng:-80.8431267
// bill: {"jan":33,"feb":33,.......}
// bill_file:"http://static.easysolarsupply.com/fslkdjfklsfjlsfdfdsj.pdf",
// has_skylight: true/false
// day_habit: high/low
// heat_source: 主要热源  electricity/other (wood fire,gas etc)
// water_heat_source: electricity/other (wood fire,gas etc)
// azimuth: 房子朝向45
// tilt_angle: 屋顶倾斜角度15
// email: fanyahui369@gmail.com
// category: GPSC
// phone: 9197411511
// install_date: As soon as possible
// recommend_solution: 456514c7-87ec-4397-8785-debc4eaa4a22