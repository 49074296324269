import Vue from 'vue'

// src/utils/filters.js
Vue.filter('orderTypeFilter',function(value) {
  if (value === 0) {
    return '商品订单';
  } else if (value === 1) {
    return '订金';
  } else {
    return value;
  }
});

Vue.filter('orderStatusFilter',function(value) {
  if (value === 0) {
    return '未付款';
  } else if (value === 1) {
    return '已付款';
  } else if (value === 2) {
    return '待发货';
  } else if (value === 3) {
    return '已发货';
  } else if (value === 4) {
    return '已收货';
  } else {
    return value;
  }
});
