import axios from 'axios';
import {Message} from 'element-ui' //Loading, 
import router from "@/router"
// 定义基础 URL
// const apiBaseUrl = 'http://192.168.1.106:8008/'; // 替换为你的 API 基础 URL
// 创建 Axios 实例
const service = axios.create({
  baseURL: '/api', // 设置基础URL 前端代理路径
  timeout: 60000, // 设置超时时间
  headers: {
    'Content-Type': 'application/json',
  },
});
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 可以在这里添加全局的请求头，例如 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // 处理响应错误
        if (error.response) {
            //console.error("wrong",error.response);
            // 服务器响应了状态码，但不是 
            //console.error('Server responded with status:', error.response.status);
            if(error.response.data.code == 402){
                //token过期，重新登录
                Message.error("token已过期，请重新登录");
                localStorage.removeItem('token');
                localStorage.removeItem('email');
                localStorage.removeItem('userInfo');
                localStorage.removeItem('user');
                localStorage.removeItem('_grecaptcha');
                setTimeout(function(){
                    router.push("/login");
                },2000);
                
            }
        } else if (error.request) {
            // 请求已发出，但没有收到响应
            //console.error('No response received:', error.request);
        } else {
            // 发生了一些问题，导致请求未发出
            //console.error('Error setting up request:', error.message);
        }
        //return Promise.reject(error);
    }
);
export default service;
