<template>
  <div class="chat-body-container">
    <div class="body-content" ref="chatContent">
      <div
        class="chat-list"
        v-for="(item, index) in chatData"
        :key="`chat-${index}`"
      >
        <div :class="['chat-content', item.isCustomer ? '' : 'not-custom']">
          <div class="header-image">
            <template v-if="!item.list">
              <img
                src="../../assets/images/botAvatar3.png"
                v-if="item.isCustomer"
              />
              <img src="../../assets/images/userAvatar.jpg" v-else />
            </template>
          </div>
          <div class="chat-text">
            <template v-if="item.text || item.list">
              <div class="chat-bg" v-if="item.text">
                <template v-if="item.isImage">
                  <img :src="item.text" />
                </template>
                <template v-else>
                  <div v-html="item.text"></div>
                </template>
              </div>
              <div v-if="item.list && item.list.length" class="questions-list">
                <div
                  v-for="(it, i) in item.list"
                  :key="`questions-${i}`"
                  class="questions-content"
                  @click="sendMessage(it.title)"
                >
                  {{ it.title }}
                </div>
              </div>
            </template>
            <div class="loading" v-else>
              <div class="loading-image" v-for="item in 3" :key="item">
                <div class="loading-ball"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    chatData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    sendMessage(val) {
      this.$emit("submitForm", val);
    },
    init() {
      const content = this.$refs.chatContent;
      if (content) {
        content.scrollTop = content.scrollHeight;
      }
    },
  },
  watch: {
    chatData: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
};
</script>
  <style lang="less" scoped>
.chat-body-container {
  height: 100%;
  overflow: hidden;
  .body-content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
    height: 100%;
    padding: 10px 10px 10px 10px;
    width: 370px;
    .chat-list {
      width: 330px;
    }
  }
}
.chat-content {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;

  .header-image {
    width: 30px;
    height: 30px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .chat-text {
    color: #000;
    max-width: 80%;
    font-size: 13px;
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;

    .chat-bg {
      background: #e3e7ee;
      border-radius: 5px 10px 10px 10px !important;
      padding: 10px;
      border-radius: 1.5em;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  &.not-custom {
    flex-flow: row-reverse;
    .chat-text {
      .chat-bg {
        background: #7eb63b;
        color: #fff;
        border-radius: 10px 5px 10px 10px;
      }
    }
  }
}
.questions-list {
  border: 1px solid #ebeef5;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0 0 10px;
  .questions-content {
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
    font-weight: bold;
    color: #7eb63b;
    padding: 10px;
    cursor: pointer;
  }
}
.loading {
  background: #e3e7ee;
  color: #000000;
  padding: 15px;
  word-wrap: break-word;
  border-radius: 5px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}
.loading-image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10px;
  height: 10px;
  .loading-ball {
    width: 10px;
    height: 10px;
    background-color: #7eb63b;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  &:first-child {
    .loading-ball {
      animation-delay: -0.32s;
    }
  }
  &:nth-child(2) {
    .loading-ball {
      animation-delay: -0.16s;
    }
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes sk-bouncedelay {
  to {
    width: 0;
    height: 0;
  }
}
</style>