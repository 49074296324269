<template>
  <div>
    <ChatTool />
    <div class="top-header d-lg-block d-none">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="top-header-infor"></div>
          </div>
          <div class="col-lg-6 col-md-6 top-header-users">
            <div class="top-header-user">
              <ul>
                <li class="dropdown-language">
                  <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    <i class="bi bi-globe-americas"></i>
                    <span style="color: #212529;">{{ $t('message.' + currentLanguage) }}</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="en" style="color: #212529;">{{ $t('message.en') }}</el-dropdown-item>
                    <el-dropdown-item command="de" style="color: #212529;">{{ $t('message.de') }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <!-- <div class="login-item">
                  <el-button type="text" @click="logout">{{ $t('message.logout') }}</el-button>
                </div> -->
                </li>
                <li class="login-item">
                  <i class="bi bi-person-circle"></i>
                  <el-dropdown v-if="user">
                    <span class="el-dropdown-link">
                      <router-link style="color: #212529 !important; font-size: 13px;" :to="{ name: 'dashboard' }"
                        >{{ user
                        }}<i class="el-icon-arrow-down el-icon--right"></i
                      ></router-link>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="logout">{{ $t('logout') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <router-link v-else to="/login" id="login">{{ $t('login') }}</router-link>
                </li>
                <li>
                  <i @click="messageButton" class="iconfont icon-xiaoxi message_icon"></i>
                  <span v-if="messageCount > 0" class="message-badge">{{ messageCount }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top-navList">
      <div class="banner">
        <div class="container">
          <div class="original-box d-lg-block d-none">
            <div class="row align-items-center">
              <div class="col-lg-2 col-xl-2 col-md-2">
                <div class="logo">
                  <router-link to="/">
                    <img src="/img/nav_logo_2.png" alt="" />
                  </router-link>
                </div>
              </div>
              <div class="col-lg-7 col-xl-7 col-md-7">
                <div class="nav-title" >
                  <!-- v-html="HeaderContent" -->
                  <!-- One-Stop Solar Products Integrator -->
                  {{ $t('message.One_StopSolarProductsIntegrator') }}
                </div>
              </div>
              <div class="col-lg-3 col-xl-3 col-md-3 head-btn align-center">
                <div class="head-right">
                  <div class="head-cart">
                    <i
                      id="shoppingCart"
                      @click="toggleCart"
                      class="iconfont icon-gouwucheman"
                    ></i>
                  </div>
                  <div class="header-button">
                    <router-link to="/contactForm">
                  {{ $t('message.Get_A_Quote') }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="original-box original_box d-lg-block d-none">
        <div class="container">
          <div class="row">
            <div class="top-nav-menu col-md-12">
              <el-menu
                background-color="#fff"
                text-color="#333"
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                router
              >
                <template v-for="(item, index) in blogHome">
                  <el-menu-item
                    :key="index"
                    :index="item.path"
                    v-if="item.children.length == 0"
                    v-show="item.isShow"
                    class="no-select liSelect"
                  >
                  <!-- @click="closeNav(item)" -->
                    {{ item.category_name }}
                  </el-menu-item>
                  <el-submenu
                    :key="index"
                    :index="item.path"
                    v-else
                    @mouseenter="handleOpen(item.id)"
                    @mouseleave="handleClose(item.id)"
                    class="el-menu-item"
                  >
                    <template slot="title">
                      <router-link
                        :to="item.path"
                        class="submenu-title no-select liSelects"
                      >
                        <span class="liSelects">{{ item.category_name }}</span>
                      </router-link>
                    </template>
                    <el-menu-item
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                      :index="child.path"
                      class="no-select child_select_name"
                    >
                      {{ child.category_name }}
                    </el-menu-item>
                  </el-submenu>
                </template>
              </el-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--自适应导航栏-->
    <!-- el-menu-item no-select liSelect -->
    <div class="responsive-box d-lg-none">
      <div class="pos-f-t">
        <nav class="navbar navbar-dark">
          <div class="nav-titles">
            <router-link  to="/"
              >Easy Solar Supply</router-link
            >
          </div>
          <button
            class="navbar-toggler"
            type="button"
            @click="toggleNav"
            data-toggle="collapse"
            data-target="#mobileNavbar"
            aria-controls="mobileNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon custom-toggler-icon"></span>
          </button>
        </nav>
        <div class="collapse" id="mobileNavbar" v-if="isNavOpen">
          <div class="mobile-head">
            <ul class="nav_scroll">
              <li v-for="item in navItems" :key="item.id">
                <a :href="item.path" >{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- 引入组件库 -->
<script src="https://unpkg.com/element-ui/lib/index.js"></script>
<script src="//at.alicdn.com/t/c/font_4719356_3ikvpozcz7l.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>


<script>
import { getBlogHome, postRouterPath,postMessage } from "@/api/api";
import ChatTool from '../views/ChatTool.vue';
export default {
  name: "HeadersList",
  components:{ChatTool},
  data() {
    return {
      currentLanguage: 'en',
      isNavOpen: false,
      activeIndex: "1",
      navItems: [],
      isDropdownOpen: false,
      isOpen: {},
      user: "",
      blogHome: [],
      router: "",
      HeaderContent: "",
      messageList: []
    };
  },
  computed: {
    lastItem() {
      return this.navItems[this.navItems.length - 1];
    },
    messageCount() {
      return this.messageList.length;
    },
  },
  methods: {
    handleCommand(command) {
      this.$i18n.locale = command; // 切换语言
      this.currentLanguage = command; // 更新当前语言
    },
    messageButton() {
      this.$router.push('/messages');
    },
    logout() {
      this.$store.commit("clearState");
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/login");
      this.$message({
        type: "success",
        message: this.$t('message.logoutSuccess'),
        duration: 2000,
      });
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    handleOpen(id) {
      this.isOpen[id] = true;
    },
    handleClose(id) {
      this.isOpen[id] = false;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 35) {
        document.querySelector(".top-navList").classList.add("fixed");
      } else {
        document.querySelector(".top-navList").classList.remove("fixed");
      }
    },
    getUser() {
      const token = localStorage.getItem("token");
    },
    toggleCart() {
      const token = localStorage.getItem("token");
      if (token) {
        if (this.$route.path === "/cart") {
          this.$router.go(0);
        } else {
          this.$router.push("/cart");
        }
      }
       else {
        this.$router.push("/login");
      }
    },
    async _postRouterPath() {
      const res = await postRouterPath({
        path: this.router
      });
    },
    async _postMessage(){
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (!user) {
        this.messageList=0
        return;
      }
      const res= await postMessage({
        user_id: user.uuid
      });
      if(res.data.code==200){
        this.messageList=res.data.data
      }
    }
  },
  created() {
    this._postRouterPath();
    this._postMessage();
  },
  async beforeCreate() {
    const res = await getBlogHome();
    if (res.data.code == 20000) {
      let router = [];
      // console.log(res.data.data,"3123123123123123123");
      this.blogHome = res.data.data;
      this.blogHome.forEach((item) => {
        router.push({
          path: item.path,
          name: item.category_name,
          component: () => import(`@/views/${item.vue_path}.vue`),
          meta: {
            content: "1111"
          }
        });
        if (item.path == this.$route.path) {
          document.title = item.seo_title;
          this.HeaderContent = item.seo_title;
        }
      });
      this.$router.addRoutes(router);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.user = localStorage.getItem("user");
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.message_icon {
  font-size: 30px;
  line-height: 50px;
   cursor: pointer;
}
.message-badge {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 42px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.el-menu-item:hover, .el-submenu__title:hover {
    color: #303133 !important;
    background: rgb(204, 204, 204);
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
/* .nav-titles {
  color: white !important;
} */
.liSelects {
  font-size: 17px;
}
li {
  list-style: none;
}
ul {
  margin: 0 !important;
}
a {
  color: #333;
  text-decoration: none;
}
.liSelect {
  font-size: 17px;
}
.top-header {
  background: #f8f8f8;
  border-bottom: 1px solid #e5e5e5;
}
.top-header .top-header-user ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.top-header .top-header-user ul li {
  margin: 0 15px;
  font-size: 13px;
}
.navbar-dark {
  padding: 10px;
}
.top-header-user {
  line-height: 40px;
}
.banner,
.responsive-box {
  background: #7eb63b;
}
/* 确保没有其他 CSS 规则覆盖 v-show 的 display 样式 */
.collapse {
  display: none !important; /* 默认隐藏 */
}
.collapse.v-show {
  display: block !important; /* 显示时的样式 */
}
.collapse {
  display: block !important; /* 确保没有设置 display: none; */
}
.mobile-head {
  width: 100%;
  background: white;
}
.nav_scroll {
  width: 100%;
  overflow: auto;
}
.nav_scroll li {
  padding: 1rem 2rem;
  border-bottom: 1px solid #e5e5e5;
  color: #333 !important;
}
.header-area .top-nav .nav-title {
  font-size: 1.75rem;
  font-weight: 500;
  /* color: #ffffff; */
  line-height: 5.25rem;
}

.header-button {
  display: inline-block;
  text-align: center;
}
.header-area .top-nav #shoppingCart {
  cursor: pointer;
}
.el-menu--horizontal > .el-menu-item {
  flex: 1;
  text-align: center;
}

#shoppingCart {
  font-size: 30px;
  color: white;
  cursor: pointer; /* 鼠标划过时显示手形光标 */
}
.head-right {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.header-area .top-nav .head-right i {
  font-size: 1.5rem;
  position: relative;
  /* color: #ffffff; */
}
.header-button a {
  background: #7eb63b;
  color: #fff;
  display: inline-block;
  padding: 12px 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  border: 1px solid #fff;
}
.header-button a {
  color: white !important;
}
.logo {
  display: flex;
  justify-content: center;
}
.footer-section {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section {
    text-align: center;
  }
}
.footer-widget-desc p {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    margin: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section .solar-conatct-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area p {
  opacity: 0.8;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  margin-top: 25px;
}
.original_box {
  border-bottom: 1px solid #e2e2e2;
  background: #fff;
}
.footer-section {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section {
    text-align: center;
  }
}
.footer-widget-desc p {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    margin: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section .solar-conatct-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.submenu-title {
  color: rgb(51, 51, 51) !important;
  font-size: 15px;
}
.el-menu.el-menu--horizontal {
  border: 0;
}
/*  */
.el-submenu__title {
  display: flex;
  padding: 0;
}

.el-submenu__title:hover {
  color: #7eb63b !important;
  background: #cecece !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}

.footer-section {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section {
    text-align: center;
  }
}
.footer-widget-desc p {
  @media only screen and (min-width: 480px) and (max-width: 599px) {
    margin: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .footer-section .solar-conatct-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area {
  border-top: 1px solid #3a4034;
  margin-top: 99px;
}
.copyright-area p {
  opacity: 0.8;
  font-size: 16px;
  line-height: 40px;
  /* color: #ffffff; */
  color: #7eb63b;
  margin-top: 25px;
}
.el-menu-item {
  /* padding: 0 50px; */
  /* margin: 0 50px; */
}
.row {
  margin-right: calc(var(--bs-gutter-x) * -0);
}

.el-menu--horizontal > .el-menu-item {
  line-height: 65px !important;
}
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  margin-top: 0 !important;
  color: red;
}
.child_select_name > li {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.align-items-center {
  align-items: center;
}
</style>