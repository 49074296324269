<template>
  <div class="page-container">
    <div class="page-fixed page-header">
      <div class="page-top">
        <div class="go-home" @click="jumpPage('/')">Home</div>
        <div class="page-language">
          <i class="iconfont icon-earth"></i> English (United States)
        </div>
      </div>
      <div class="container">
        <div class="page-logo">
          <div class="page-logo-image">
            <img src="/img/logo-no-letter.jpg" />
            <div class="logo-title">Easysolarsupply.com</div>
          </div>
          <div class="message-icon">
            <i @click="jumpPage('/messages')" class="iconfont icon-xiaoxi"></i>
            <span v-if="messageList.length" class="message-badge">{{
              messageCount
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="page-main-content">
      <div class="container">
        <div class="page-nav" v-if="$route.name !== 'dashboard'">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              :to="{ path: '/dashboard' }"
              >Dashboard</el-breadcrumb-item
            >
            <el-breadcrumb-item v-if="$route.meta && $route.meta.pageName">{{
              $route.meta.pageName
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="page-title">
          {{ $route.meta ? $route.meta.pageName : "" }}
        </div>
        <slot />
      </div>
    </div>
    <div class="page-fixed page-footer">
      Copyright @ Bonck America Technologies Co. Ltd. {{ currentYear - 1 }}-{{
        currentYear
      }}. All rights reserved
    </div>
  </div>
</template>
<script>
import { postMessage } from "@/api/api";
export default {
  props: {
    desc: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      messageList: [],
    };
  },
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
  created() {
    this.getPostMessage();
  },
  methods: {
    jumpPage(url) {
      if (url) {
        this.$router.push(url);
      }
    },
    async getPostMessage() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      if (!user) {
        this.$router.push("/login");
        return;
      }
      const res = await postMessage({
        user_id: user.uuid,
      });
      if (res?.data?.code == 200) {
        this.messageList = res.data.data;
      } else {
        this.messageList = [];
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-container {
  color: #333;
  height: 100%;
  .container{
    padding: 0!important;
    .row{
      padding: 0!important;
      margin: 0!important;
      >div{
        padding: 0 10px!important;
      }
    }
  }
  .page-fixed {
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    background: #fff;

    &.page-header {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    &.page-footer {
      top: auto;
      bottom: 0;
      background: #7eb63b;
      display: flex;
      align-items: center;
      height: 30px;
      justify-content: center;
    }
    .page-top {
      background: #7eb63b;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      font-size: 14px;
    }
    .go-home {
      cursor: pointer;
      font-size: 16px;
    }
    .page-language {
      i {
        position: relative;
        bottom: -2px;
      }
    }
    .page-logo {
      height: 60px;
      background: #fff;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .page-logo-image {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
        white-space: nowrap;

        img {
          display: block;
          height: 50px;
          width: auto;
        }
      }
      .message-icon {
        cursor: pointer;
        position: relative;
        bottom: -4px;
        .icon-xiaoxi {
          font-size: 36px;
        }
      }
    }
  }

  .page-main-content {
    background-color: #f8f8f8;
    padding: 140px 0 50px 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    .container{
      min-height: 100%;
      display: flex;
      flex-flow: column;
    }
  }
  ::v-deep .el-breadcrumb {
    .el-breadcrumb__inner {
      color: #333;
      line-height: 28px;
      font-size: 16px;
      &.is-link {
        color: #333;
        cursor: pointer;
        font-weight: normal;
        &:hover {
          color: #7eb63b;
        }
      }
    }
  }
  .page-nav{
    margin-bottom: 10px;
  }
  .page-title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}
.el-button{
  color: #7eb63b;
  span{
    color: #7eb63b;
  }
}
</style>
