import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // component: HomeView
    component: () => import('../views/index.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/headersList',
    name: 'headersList',
    component: () => import('../views/HeadersList.vue')
  },
  // ================================================= 
  {
    path: '/residential',
    name: 'residential',
    component: () => import('../views/Residential.vue')
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: () => import('../views/Commercial.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/howtopurchase',
    name: 'purchase',
    component: () => import('../views/Purchase.vue')
  },
  {

    path: '/services',
    name: 'service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/ecosystem',
    name: 'ecosystem',
    component: () => import('../views/Ecosystem.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('../views/ProductDetails.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }, {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue')
  },
  {
    path: '/regiser',
    name: 'regiser',
    component: () => import('../views/regiser.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      pageName: "My Account"
    }
  }, {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/components/Orders.vue'),
    meta: {
      pageName: "My Orders"
    }
  }, {
    path: '/designOrder',
    name: 'designOrder',
    component: () => import('../views/components/DesignOrder.vue'),
    meta: {
      pageName: "My Design orders"
    }
  }, {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/components/Cart.vue'),
    meta: {
      pageName: "My Cart"
    }
  }
  , {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/Pay.vue'),

  }, {
    path: '/Selection',
    name: 'Selection',
    component: () => import('../views/Selection.vue')
    // ResidentialDetails
  }, {
    path: '/calculator',
    name: 'calculator',
    component: () => import('../views/Calculator.vue')
  },
  {
    path: '/header',
    name: 'header',
    component: () => import('../views/components/Header.vue')
  }, {
    path: '/stepList',
    name: 'stepList',
    component: () => import('../views/components/StepList.vue')
  }, {
    path: '/yourHouse',
    name: 'yourHouse',
    component: () => import('../views/components/YourHouse.vue')
  }, {
    path: '/yourBill',
    name: 'yourBill',
    component: () => import('../views/components/YourBill.vue')
  },
  {
    path: '/yourUse',
    name: 'yourUse',
    component: () => import('../views/components/YourUse.vue')
  },
  {
    path: '/yourReport',
    name: 'yourReport',
    component: () => import('../views/components/YourReport.vue')
  }, {
    path: '/installer',
    name: 'installer',
    component: () => import('../views/Installer.vue')
  }
  , {
    path: '/paySuccessful',
    name: 'paySuccessful',
    component: () => import('../views/components/PaySuccessful.vue')
  }, {
    path: '/consultation',
    name: 'consultation',
    component: () => import('../views/Consultation.vue')
  }, {
    path: '/design',
    name: 'design',
    component: () => import('../views/Design.vue')
  }, {
    path: '/manual',
    name: 'manual',
    component: () => import('../views/Manual.vue')
  }, {
    path: '/contactForm',
    name: 'contactForm',
    component: () => import('../views/ContactForm.vue')
  }, {
    path: '/designCost',
    name: 'designCost',
    component: () => import('../views/DesignCost.vue')
  }, {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('../views/OrderDetails.vue')
  }, {
    path: "/dashboardOrderDetial",
    name: "dashboardOrderDetial",
    component: () => import('../views/components/DashboardOrderDetial.vue')
  }, {
    path: "/profile",
    name: "profile",
    component: () => import('../views/components/Profile.vue'),
    meta: {
      pageName: "My Profile"
    }
  }, {
    path: "/consultants",
    name: "consultants",
    component: () => import('../views/Consultants.vue')
  }, {
    path: "/footerList",
    name: "footerList",
    component: () => import('../views/FooterList.vue')
  }, {
    path: "/warranty",
    name: "warranty",
    component: () => import('../views/Warranty.vue')
  }, {
    path: "/contractor",
    name: "contractor",
    component: () => import('../views/Contractor.vue')
  }
  , {
    path: "/messages",
    name: "messages",
    component: () => import('../views/Messages.vue')
  }
  , {
    path: "/article/:id",
    name: "ArticleView",
    component: () => import('../views/ArticleView.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history', // 设置模式为 'history'
})
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token');
  if (requiresAuth && !token) {
    Vue.prototype.$message({
      message: 'The login has timed out, please log in again',
      type: 'warning'
    });
    to.meta.title && (document.title = to.meta.title);
    next('/login');
  } else {
    next();
  }
});
// howtopurchase
export default router
// router.beforeEach((to, from, next) => {  if (to.name!== 'Login' &&!isLoggedIn) { next({ name: 'Login' }); } else { next(); } });