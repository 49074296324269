<template>
  <div class="table-container">
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column type="index" width="80" label="Index"> </el-table-column>
      <el-table-column
        v-for="(item, index) in tableColumns"
        :key="`table-${index}`"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <template v-if="item.isLink">
            <el-button
              type="text"
              @click="handleClick(scope.row, scope.row[item.prop], item.prop)"
              >{{ scope.row[item.prop] }}</el-button
            >
          </template>
          <template v-else>{{ scope.row[item.prop] }}</template>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="pageConfig.pageSizes"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Object,
      default: () => {
        return {
          pageSizes: 10,
          currentPage: 1,
          total: 0,
        };
      },
    },
    pageConfig: {
      type: Object,
      default: () => {
        return {
          pageSizes: [10, 20, 50, 100],
        };
      },
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleClick(row, value, prop) {
      this.$emit("handleClick", row, value, prop);
    },
  },
};
</script>
<style lang="less" scoped>
.table-container {
  padding: 20px;
  background: #fff;
  display: flex;
  flex-flow: column;
  .el-table {
    flex: 1;
    overflow: hidden;
  }
  .pagination {
    padding-top: 20px;
  }
  ::v-deep {
    .el-pagination__sizes .el-input .el-input__inner:hover {
      border-color: #C0C4CC;
    }
    .ascending {
      .caret-wrapper {
        .ascending {
          border-bottom-color: #7eb63b;
        }
      }
    }
    .descending {
      .caret-wrapper {
        .descending {
          border-top-color: #7eb63b;
        }
      }
    }
  }
}
</style>
  