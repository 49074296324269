<template>
  <div class="chat-footer-container">
    <el-form
      class="rule-form"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      @submit.native.prevent
    >
      <div class="chat-toolbar">
        <div class="toolbar-cell">
          <el-dropdown trigger="click" @command="handleCommand">
            <img src="../../assets/images/addbq.png" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in emojiList"
                :key="`emoji-${index}`"
                :command="item.label"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="toolbar-cell">
          <img src="../../assets/images/upload.png" />
          <div class="upload-box">
            <el-upload
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-change="handleChange"
              :accept="accept"
            >
            </el-upload>
          </div>
        </div>
        <el-form-item label="" prop="phone">
          <div class="send-message">
            <el-input
              v-model="ruleForm.phone"
              placeholder="Enter PN..."
              @keyup.enter.native="submitForm"
            ></el-input>
            <el-button @click="submitForm">Tel contact</el-button>
          </div>
        </el-form-item>
      </div>
      <el-form-item label="" prop="message">
        <div class="send-message">
          <el-input
            type="textarea"
            v-model="ruleForm.message"
            placeholder="Type a message..."
            @keydown.enter.native="handleEnter"
            ref="textareaMessage"
          ></el-input>
          <div class="send-btn">
            <i
              class="el-icon-s-promotion"
              @click="submitForm($event, 'message')"
            ></i>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      ruleForm: {
        phone: "",
        message: "",
      },
      fileList: [],
      emojiList: [
        { label: "😀", value: "0" },
        { label: "😁", value: "1" },
        { label: "😅", value: "2" },
        { label: "😂", value: "3" },
        { label: "😎", value: "4" },
        { label: "😍", value: "5" },
        { label: "😘", value: "6" },
        { label: "🥺", value: "7" },
      ],
      rules: {
        phone: [{ required: true, message: "请输入", trigger: "change" }],
      },
      accept:
        ".xbm,.tif,.jfif,.ico,.tiff,.gif,.svg,.jpeg,.svgz,.jpg,.webp,.png,.bmp,.pjp,.apng,.pjpeg,.avif",
    };
  },
  methods: {
    handleCommand(command) {
      this.ruleForm.message = command;
      this.$nextTick(() => {
        this.$refs.textareaMessage.focus();
      });
    },
    handleEnter(event) {
      if (!event.shiftkey) {
        event.preventDefault();
        this.submitForm(event, "message");
      }
    },
    submitForm(event, flag) {
      const { phone, message } = this.ruleForm;
      if (flag) {
        if (!message) {
          return;
        }
        this.$emit("submitForm", message, flag);
        this.$nextTick(() => {
          this.ruleForm.message = "";
        });
        console.log("提交表单 message:", message);
      } else {
        if (!phone) {
          return this.$message.warning("Please enter a valid telephone number");
        }
        this.$emit("submitForm", phone);
        this.$nextTick(() => {
          this.ruleForm.phone = "";
        });
        console.log("提交表单 phone:", phone);
      }
    },
    handleChange(file, fileList) {
      console.log(file, fileList, "====filefile====");
      this.fileList.push(file);
      const url = URL.createObjectURL(file.raw);
      console.log(url, "=============");
      this.$emit("submitForm", url, "image");
    },
    handleAvatarSuccess(res, file) {
      // const url = URL.createObjectURL(file.raw);
      // this.$emit("submitForm", url, "image");
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>
    <style lang="less" scoped>
.chat-toolbar {
  display: flex;
  align-items: center;
  .toolbar-cell {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    &:hover {
      background: #f1f1f1;
    }
    img {
      display: block;
      width: 20px;
      height: 20px;
      position: relative;
      z-index: 1;
    }
    .emoji-popup {
      position: absolute;
    }
  }
  .el-form-item {
    padding-left: 20px;
  }
}
.el-form.rule-form {
  .el-form-item {
    margin-bottom: 0;
    .send-message {
      display: flex;
      align-items: center;
      position: relative;
      .el-button {
        height: 22px;
        border-radius: 0 20px 20px 0;
        padding: 0 10px;
        font-size: 12px;
        background: #7eb63b;
        border: none !important;
        color: #fff !important;
        position: relative;
        bottom: -1px;
      }
      .send-btn {
        background: #fff;
        height: 100%;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        color: #7eb63b;
        font-size: 24px;
        i {
          cursor: pointer;
        }
      }
    }
    ::v-deep {
      .el-input__inner {
        height: 22px;
        border: 1px solid #9e9e9e;
        border-right: 0;
        border-radius: 20px 0 0 20px;
        font-size: 12px;
        padding: 0 10px;
        outline: none !important;
      }
      .el-textarea__inner {
        height: 40px !important;
        padding: 5px 20px 5px 10px !important;
        width: 100%;
        font-size: 12px;
        resize: none;
        border: none !important;
      }
    }
  }
}
.el-popper {
  width: 280px;
  top: auto !important;
  bottom: 97px;
  left: auto !important;
  right: 90px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  ::v-deep .popper__arrow {
    left: 22px !important;
  }
  .el-dropdown-menu__item {
    padding: 0 12px;
    font-size: 20px;
    &:hover {
      background: #eee;
    }
  }
}
.upload-box {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
::v-deep {
  .el-upload-list {
    display: none !important;
  }
  .el-upload {
    display: block;
  }
}
</style>