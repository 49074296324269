import request from '@/utils/request';
// 登录
export function webhook(data) {
  return request({
    data,
    url: '/',
    method: 'post',
    baseURL:'/webhook',
  })
}
export function webhooks(data) {
  return request({
    data,
    url: '/rest/webhook',
    method: 'post',
    baseURL:'webhooks',
  })
}
